import { useEffect, useState } from 'react';

import { Trans } from '@lingui/react';
import { Banner, BannerProps, Box, Button, Flex } from '@nestoca/ui';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { constSelector, useRecoilValue } from 'recoil';

import { ProblemSidebarNotification } from 'components/sidebar-problems-notification';
import { getSubmissionNoteChangedAfterSubmitted } from 'components/submission-notes/utils';
import { SyncSubNotesState } from 'constants/banner-notifications';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    getCurrentAppMainApplicant,
    selectedApplication,
    sidebarNotification,
} from 'store/applications';
import {
    defaultDecisionEngineReportDetailsState,
    getDecisionEngineReportDetails,
} from 'store/decision-engine';
import { getQualificationState } from 'store/qualification';
import { useShowDigitalFlag } from 'store/rights';
import { getSubmissionNotesState } from 'store/submission-notes';
import { mapLenderToLenderId } from 'utils/application';
import { useIsTenant } from 'utils/useIsTenant';

import { SyncSubmissionNotes } from './banner-content';
import styles from './banner-notifications.module.scss';
import { Popup } from './popup';
import { getSyncSubmissionNotesBannerVariant } from './utils';

export type NotificationOptions = {
    id: string;
    children: React.ReactNode;
    variant?: BannerProps['variant'];
    visible?: BannerProps['visible'];
};

type Props = {
    applicationId: number;
};

export const BannerNotifications = ({ applicationId }: Props) => {
    const { i18n } = useI18n();

    const [showNotifications, setShowNotifications] = useState(false);

    const [syncSubmissionNotesState, setSyncSubmissionNotesState] = useState(
        SyncSubNotesState.EMPTY
    );

    // ******************************************************************************************
    // ****** TODO: THIS SHOULD BE DEPRECATED IN FAVOR OF THE NEW TENANT FEATURES FLAG    *******
    // ****** [epic: SEAL-787](https://nestoca.atlassian.net/browse/SEAL-787)             *******
    // ******                                                                             *******
    // ****** Please be aware that the new tenant features flag is not yet implemented    *******
    // ****** if you have request to add more tenant slug to this condition.              *******
    // ****** remind your P.O. we should build the tenant specific features flag project. *******
    // ****** We want to keep thing scalable and maintainable.                            *******
    // ******                                                                             *******
    // ******************************************************************************************
    const { isIGTenant } = useIsTenant();

    const { optimusPdf } = useFlags();

    const mainApplicant = useRecoilValue(getCurrentAppMainApplicant);

    const application = useRecoilValue(selectedApplication);

    const qualification = useRecoilValue(getQualificationState(applicationId));

    const hasProblemSidebarNotifications = useRecoilValue(sidebarNotification);

    const submissionNotesList = useRecoilValue(
        getSubmissionNotesState({ applicationId })
    );

    const showDigitalFlag = useShowDigitalFlag(application?.isNonDigital);

    // logic for checking submission notes should only apply on the most recent submission note
    const currentSubmissionNote = submissionNotesList[0];

    const mainApplicantCreditScore = mainApplicant?.creditReport?.score || 0;

    const isCreditScoreBannerVisible =
        isIGTenant &&
        mainApplicantCreditScore < 700 &&
        !!qualification?.helocAmount;

    const { purpose: propertyPurpose, numberOfUnits: propertyUnits } =
        application?.property || {};

    const preAppSubmissionNoteSubmitted =
        currentSubmissionNote?.state === 'SUBMITTED' &&
        currentSubmissionNote?.applicationFieldsSnapshot?.applicationSubType ==
            'PRE_APPROVAL' &&
        !!application?.preApprovalGoLiveDate;

    const submissionNoteChangedAfterSubmitted =
        getSubmissionNoteChangedAfterSubmitted(
            currentSubmissionNote,
            application
        );

    const isSyncSubmissionNotesBannerVisible =
        currentSubmissionNote !== undefined &&
        currentSubmissionNote.state !== 'REJECTED' &&
        syncSubmissionNotesState !== SyncSubNotesState.EMPTY &&
        !submissionNoteChangedAfterSubmitted &&
        !preAppSubmissionNoteSubmitted;

    const isRateLockIgBannerVisible =
        isIGTenant && application?.mortgage?.rateLock;

    const isHelocOwnerOccupiedBannerVisible =
        isIGTenant &&
        !!qualification?.helocAmount &&
        !['OWNER_OCCUPIED', 'OWNER_OCCUPIED_AND_RENTAL'].includes(
            propertyPurpose
        );

    const isPropertyUnitsBannerVisible =
        isIGTenant && propertyUnits > 4 && !!qualification?.helocAmount;

    const isGoLiveBannerVisible =
        !!application?.preApprovalGoLiveDate &&
        !!currentSubmissionNote?.applicationFieldsSnapshot?.applicationSubType;

    const isNonDigitalBannerVisible = showDigitalFlag;

    const decisionEngineReportDetails = useRecoilValue(
        applicationId
            ? getDecisionEngineReportDetails(applicationId)
            : constSelector(defaultDecisionEngineReportDetailsState)
    );

    const subjectPropertyLender = application?.property?.mortgages[0]?.lender;
    const productLenderId = application?.product?.lenderId;
    const mappedLenderId = mapLenderToLenderId(subjectPropertyLender);

    const isIGApplicationTransferToRenewal =
        isIGTenant &&
        application?.type === 'TRANSFER' &&
        mappedLenderId &&
        mappedLenderId === productLenderId;

    const isIGApplicationRenewalToTransfer =
        isIGTenant &&
        application?.type === 'RENEWAL' &&
        mappedLenderId &&
        mappedLenderId !== productLenderId;

    useEffect(() => {
        const { EMPTY, WARNING } = SyncSubNotesState;

        const { applicationUpdatedAt, submittedAt } =
            currentSubmissionNote || {};

        // if the submission note is not "SUBMITTED" yet, submittedAt from BE is null and the next line returns false, so we set the state to EMPTY
        applicationUpdatedAt > submittedAt
            ? setSyncSubmissionNotesState(WARNING)
            : setSyncSubmissionNotesState(EMPTY);
    }, [currentSubmissionNote]);

    const notificationsOptions: NotificationOptions[] = [
        {
            id: 'problem-sidebar',
            children: (
                <ProblemSidebarNotification
                    applicationId={applicationId}
                    closeNotifications={() => setShowNotifications(false)}
                />
            ),
            variant: 'error',
            visible: hasProblemSidebarNotifications,
        },
        {
            id: 'go-live-banner',
            children: i18n._('goLiveBanner'),
            visible: isGoLiveBannerVisible,
            variant: 'warning',
        },
        {
            id: 'sync-submission-notes',
            children: (
                <SyncSubmissionNotes
                    applicationId={applicationId}
                    submissionNotes={currentSubmissionNote}
                    syncSubmissionNotesState={syncSubmissionNotesState}
                    setSyncSubmissionNotesState={setSyncSubmissionNotesState}
                />
            ),
            variant: getSyncSubmissionNotesBannerVariant(
                syncSubmissionNotesState
            ),
            visible: isSyncSubmissionNotesBannerVisible,
        },
        {
            id: 'rate-locked',
            children: i18n._('rateLocked.banner'),
            visible: isRateLockIgBannerVisible,
        },

        {
            id: 'heloc-validation-fico',
            children: !mainApplicantCreditScore
                ? i18n._('helocValidation.missingFico')
                : i18n._('helocValidation.lowFico'),
            variant: 'error',
            visible: isCreditScoreBannerVisible,
        },
        {
            id: 'heloc-validation-occupancy',
            children: i18n._('helocValidation.occupancy'),
            variant: 'error',
            visible: isHelocOwnerOccupiedBannerVisible,
        },
        {
            id: 'heloc-validation-units',
            children: i18n._('helocValidation.units'),
            variant: 'error',
            visible: isPropertyUnitsBannerVisible,
        },
        {
            id: 'non-digital-application',
            children: i18n._('nonDigitalApplication.banner'),
            variant: 'warning',
            visible: isNonDigitalBannerVisible,
        },
        {
            id: 'optimus',
            children: (
                <div>
                    <Trans
                        id="optimus.banner"
                        components={{ strong: <strong /> }}
                    />
                </div>
            ),
            variant: 'warning',
            visible:
                !isIGTenant &&
                decisionEngineReportDetails.hasNotification &&
                optimusPdf,
        },
        {
            id: 'ig-investor-to-lender-transfer',
            children: i18n._('investorToLenderID.banner.transfer'),
            variant: 'warning',
            visible: isIGApplicationRenewalToTransfer,
        },
        {
            id: 'ig-investor-to-lender-renewal',
            children: i18n._('investorToLenderID.banner.renewal'),
            variant: 'warning',
            visible: isIGApplicationTransferToRenewal,
        },
    ];

    const getNbOfNotifications = () =>
        notificationsOptions.filter((notificationOptions) =>
            notificationOptions.visible === undefined
                ? true
                : notificationOptions.visible
        ).length;

    const handleShowNotifications = () => {
        setShowNotifications(true);
    };

    const onHideNotifications = () => {
        setShowNotifications(false);
    };

    const getBannerVariant = () => {
        const visibleNotifications = notificationsOptions.filter(
            (notificationOptions) =>
                notificationOptions.visible === undefined
                    ? true
                    : notificationOptions.visible
        );

        const hasError = visibleNotifications.some(
            (notificationOptions) => notificationOptions.variant === 'error'
        );

        if (hasError) {
            return 'error';
        }

        return 'info';
    };

    return (
        <Box className={styles['banner-notifications']}>
            <Banner hasDropShadow={true} variant={getBannerVariant()}>
                <Flex align="center" gap={3}>
                    {i18n._('banner.notifications', {
                        nbOfNotifications: getNbOfNotifications(),
                    })}
                    {getNbOfNotifications() > 0 && (
                        <Button
                            className={cn(styles['view-button'], {
                                [styles['view-button--active']]:
                                    showNotifications,
                            })}
                            size="small"
                            variant="link"
                            onClick={handleShowNotifications}
                        >
                            {i18n._('view')}
                        </Button>
                    )}
                </Flex>
            </Banner>
            {showNotifications && (
                <Popup
                    notificationsOptions={notificationsOptions}
                    nbOfNotifications={getNbOfNotifications()}
                    onHideNotifications={onHideNotifications}
                />
            )}
        </Box>
    );
};
